/* # FUNCTIONS
========================================================================== */


$(function () {

    "use strict";
    var $window = $(window);
    var $document = $(document);
    var winWidthSm = 991;
    var $body = $('body');

    $(document).ready(function () {
        //int_introHeight();
        scroll();
        sidebarMenu();
        //pluginElement();
        //shortcodeElements();
    });


    // ------------------------------------------------------- //
    //   Item Slider
    // ------------------------------------------------------ //
    $('.featured-items-slider').owlCarousel({
        loop: false,
        margin: 30,
        nav: false,
        dots: true,
        //navText: ['<img src="../img/prev.svg" alt="" width="50">', '<img src="../img/next.svg" alt="" width="50">'],
        smartSpeed: 400,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 3
            }
        }
    });


    //---------------------------------------------------------------------------------------------------------------------------->
    //      Sidebar Menu
    //---------------------------------------------------------------------------------------------------------------------------->


    function sidebarMenu() {

        var sidebarMenuBtn = $('.menu-mobile-btn'),
            sidebarMenu = $('.sidebar-menu'),
            sidebarMenuClose = $('.sidebar-close-btn'),
            sidebarBtnActive = 'active',
            sidebarMenuActive = 'sidebar-menu-open';

        function opensidebar() {
            sidebarMenuBtn.toggleClass(sidebarBtnActive);
            sidebarMenu.toggleClass(sidebarMenuActive);
        }

        function closesidebar() {
            sidebarMenuBtn.removeClass(sidebarBtnActive);
            sidebarMenu.removeClass(sidebarMenuActive);
        }

        sidebarMenuClose.on('click', function (event) {
            closesidebar();
        });

        sidebarMenuBtn.on('click', function (event) {
            event.stopPropagation();
            opensidebar();
        });

        $document.on('click touchstart', function (event) {
            if (!$(event.target).closest('.sidebar-menu, .mobile-menu-trigger').length) {
                closesidebar();
            }
        });
    };

    var mainMenuOpened = null;
	$("#sidebar-menu").children('li').each(function() {
        
        var _this = $(this);
		if (!_this.hasClass("active") && !_this.children("ul").children("li").hasClass("active")) {
            _this.children("ul").toggle();
            _this.children("ul").addClass("closed");
		}
		else {
			mainMenuOpened = _this.children("ul");
            _this.children("ul").addClass("opened");
		}
		
		_this.children("a").click(function(e) {
			e.preventDefault();
            var currentUl = $(this).next("ul");

            if (currentUl != undefined)
            {
                if (currentUl.hasClass("opened"))
                {
                    mainMenuOpened = null;
                    currentUl.addClass("closed");
                    currentUl.removeClass("opened");
                    currentUl.toggle();
                }
                else
                {
                    if (mainMenuOpened != null && mainMenuOpened != undefined) {
                        mainMenuOpened.addClass("closed");
                        mainMenuOpened.removeClass("opened");
                        mainMenuOpened.toggle();
                    }

                    mainMenuOpened = currentUl;
                    currentUl.addClass("opened");
                    currentUl.removeClass("closed");
                    currentUl.toggle();
                }
            }
            
		});
	});



    // ---------------------------------------------------------------------------------------------------------------------------->
    // SCROLL FUNCTIONS   ||-----------
    // ---------------------------------------------------------------------------------------------------------------------------->

    /* Accordian Arrow Added*/
    $('ul.dd-accordion > li:has( > ul ) > a').append("<span class='dd-accordionIcon'></span>");
    /* Clickeble Link */
    $('ul.dd-accordion > li:has( > ul ) > a').attr('href', 'javascript:void(0)');
    /* Accordian Sub Childern "ul" Hide */
    $('ul.dd-accordion li ul').hide();

    var ddAccordionClick = $('ul.dd-accordion li a');
    var ddAccordionHeader = $('ul.dd-accordion > li > a');

    /* Dropdown accordion */
    ddAccordionClick.on('click', function (e) {
        ddAccordionClick.each(function (i) {
            if ($(this).next().is("ul") && $(this).next().is(":visible")) {
                $(this).next().slideUp();
            }
        });

        var e = $(e.target);
        if (e.next().is("ul") && e.next().is(":visible")) {
            e.next().slideUp();

        } else {
            e.next().slideDown();

        }
    });

    /* Accordian Icon */
    ddAccordionClick.on('click', function (e) {
        if ($(this).hasClass('is-active')) {
            $(this).removeClass('is-active');
        }
        else {
            /* close other content */
            ddAccordionHeader.not(this).removeClass('is-active');
            $(this).addClass('is-active');
        }
    });

    // ---------------------------------------------------------------------------------------------------------------------------->
    // SCROLL FUNCTIONS   ||-----------
    // ---------------------------------------------------------------------------------------------------------------------------->

    function scroll() {

        // //Click Event to Scroll to Top
        $(window).scroll(function () {
            if ($(this).scrollTop() > 300) {
                $('.scroll-top').fadeIn();
            } else {
                $('.scroll-top').fadeOut();
            }
        });
        $('.scroll-top').click(function () {
            $('html, body').animate({ scrollTop: 0 }, 800);
            return false;
        });

        // Scroll Down Elements
        $('.scroll-down[href^="#"], .scroll-to-target[href^="#"]').on('click', function (e) {
            e.preventDefault();

            var target = this.hash;
            var $target = $(target);

            $('html, body').stop().animate({
                'scrollTop': $target.offset().top
            }, 900, 'swing', function () {
                window.location.hash = target;
            });
        });

    };

    // ---------------------------------------------------------------------------------------------------------------------------->
    // TOASTR   ||-----------
    // ---------------------------------------------------------------------------------------------------------------------------->
    function LaunchErrorAlert(title, description) {
        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-full-width",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": 300,
            "hideDuration": 100,
            "timeOut": 0,
            "extendedTimeOut": 0,
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }
        Command: toastr["error"](description, title)
    }

    function LaunchWarningAlert(title, description) {
        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-full-width",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": 300,
            "hideDuration": 100,
            "timeOut": 0,
            "extendedTimeOut": 0,
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }
        Command: toastr["warning"](description, title)
    }

    function LaunchInfoAlert(title, description) {
        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-full-width",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": 300,
            "hideDuration": 100,
            "timeOut": 6000,
            "extendedTimeOut": 1000,
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }
        Command: toastr["info"](description, title)
    }

    function LaunchSuccessAlert(title, description) {
        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-full-width",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": 300,
            "hideDuration": 100,
            "timeOut": 6000,
            "extendedTimeOut": 1000,
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }
        Command: toastr["success"](description, title)
    }

    // LANZAMOS ERRORES CON TOASTR
    $(".toastr-alert-error").each(function () {
        LaunchErrorAlert("", $(this).html());
    });
    $(".toastr-alert-warning").each(function () {
        LaunchWarningAlert("", $(this).html());
    });
    $(".toastr-alert-info").each(function () {
        LaunchInfoAlert("", $(this).html());
    });
    $(".toastr-alert-success").each(function () {
        LaunchSuccessAlert("", $(this).html());
    });

    // ------------------------------------------------------- //
    //   Tooltip
    // ------------------------------------------------------ //

    $('[data-toggle="tooltip"]').tooltip();

    // ------------------------------------------------------- //
    // Quantity
    // ------------------------------------------------------ //

    $(function () {
        var qty_min = $('.quantity').attr("min");
        var qty_max = $('.quantity').attr("max");

        $(".quantityPlus").on('click', function () {
            var currentVal = parseInt($(this).next(".quantity").val(), 10);
            var str = $("p:first").text();
            if (currentVal != qty_max) {
                $(this).next(".quantity").val(currentVal + 1);
            }
        });

        $(".quantityMinus").on('click', function () {
            var currentVal = parseInt($(this).prev(".quantity").val(), 10);
            if (currentVal != qty_min) {
                $(this).prev(".quantity").val(currentVal - 1);
            }
        });
    });


});
