global.$ = require("jquery");
global.jQuery = global.$;

// BOOTSTRAP JS & STYLES
import 'bootstrap';
import "../src/custom/scss/custom-template.scss";

//// CUSTOM STYLES, EXPORTS & JS

import "../src/custom/_custom-app.js";

/* # PLUGINS
========================================================================== */

import './_plugins.js';

/* # FUNCTIONS
========================================================================== */
import './_functions.js';

//TOASTR

import toastr from 'toastr';

window.toastr = toastr;

import "./plugins/toastr/toastr.scss";